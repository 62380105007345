:root {
  /* colors */
  --dark: #2f2f3a;
  --dark-solid: #0d0d27;
  --light: #7d8890;
  --lighter: #f4f7fc;
  --shadow: #dcdfe6;
  --success: #2ddaba;
  --success-light: #dcfff8;
  --danger: #ea4955;
  --danger-light: #ffe9f6;
  --warning: #ffbc02;
  --warning-light: #fff0e1;
  --primary: #1a92ff;
  --primary-light: #d9edff;
  --purple: #5849b5;
  --purple-light: #dfdaff;
  --dark-pink: #ea4c89; 
  --dark-pink-light: #ff70a7; 
  --pink-light: #ffe6ef; 


  /* nav */
  --nav-bg: #dcdfe6;
}

html, body{
  overflow-x: hidden;
}


/* Focusing the button with a keyboard will show a dashed black line. */
button:focus {
  outline: none;
}

input,
optgroup,
select,
textarea:focus {
  outline: none;
}

.pop-modal{
  border: none;
  border-radius: 30px;
}

.pop-bg::-webkit-scrollbar{
  display: none;
}

small.small{
  color: var(--dark);
  font-size: 14px;
}


button[disabled]{
  color: var(--white);
  background: var(--light);
  cursor: not-allowed;
}

button[disabled]:hover{
  color: var(--white);
  background: var(--light);
}

.pop-header{
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0;
  margin-bottom: 25px;
}

.pop-bg {
  background: rgba(0, 0, 0, 0.678);
}

.pop-inner-container {
  position: fixed;
  background: white;
  z-index: 2;
}

.pop-inner-container .inner {
  position: relative;
  padding: 30px;
  overflow-y: auto;
  max-height: 100vh;
  z-index: 3;
  width: 60vw;
}

.pop-inner-container .inner::-webkit-scrollbar{
  background: none;
  width: 5px;
  border-radius: 50px;
}

.pop-inner-container .inner::-webkit-scrollbar-thumb{
  background: none;
  border-radius: 50px;
}

/* Position */
/* Position Right */
.pop-r {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
/* Position Left */
.pop-l {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

/* pop width */
.pop-w-1 {
  width: 25%;
}

.pop-w-2 {
  width: 30%;
}

.pop-w-3 {
  width: 40%;
}

.pop-w-4 {
  width: 50%;
}

.pop-w-5 {
  width: 60%;
}

.pop-w-6 {
  width: 70%;
}

/* Responsive Mobile View */
@media (max-width: 700px) {
  .pop-w-1,
  .pop-w-2,
  .pop-w-3 {
    width: 100%;
  }

  .pop-r, .pop-l{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .pop-inner-container{
    top: 15vh;
    max-height: 85vh;
    bottom: 0;
  }

  .pop-inner-container .inner {
    max-height: 85vh;
  }
}


/* DEMO STYLE */
body{
  font-family: 'Inter';
  background-color: #F5F4F7;
}

.title{
  font-weight: 800;
  font-size: 40px;
  color: black;
}

img{
  margin-bottom: 12px;
  border-radius: 10px;
}

.action-btn{
  width: 100%;
  height: 50px;
  border: none;
  background: var(--purple);
  color: white;
  border-radius: 10px;
}

.action-danger{
  width: 100%;
  height: 50px;
  border: none;
  background: var(--dark-pink);
  color: white;
  border-radius: 10px;
}


/* main dash */
.ver{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main .tickets .ticket{
  background: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(185, 185, 185, 0.13);
  width: 100%;
  margin-bottom: 20px;
}

.main .tickets .ticket .t-title{
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 5px;

}

.main .tickets .ticket p{
  font-size: 14px;
}

.main .tickets .ticket .top{
  margin-top: -10px;
}

.main .tickets .ticket .bottom{
  margin-top: 15px;
  margin-bottom: -15px;
}

.main .tickets .ticket .t-light{
  color: #807c8b;
  font-weight: 800;
}

.main .tickets .ticket .date{
  color: var(--light);
}


.badge-purple{
  background: var(--purple);
  color: white;
}

.search{
  position: relative;
}

.search-btn{
  border: none;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: transparent;
  color: var(--dark);
}

.search-btn.active{
  color: var(--dark-pink);
}

.search-btn:hover{
  color: var(--dark-pink);
}

.search input{
  border: none;
  height: 40px;
  width: 100%;
  border-radius: 10px;
  padding-left: 35px;
  box-shadow: 0 0 10px 0 rgba(185, 185, 185, 0.13);
}

.search input::placeholder{
  color: var(--dark);
}

.search button{
  border: none;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: transparent;
  color: var(--dark);
  position: absolute;
  left: -3px;
}


@keyframes slide-in {
  0%{
    width: 0%;
  }

  100%{
    width: 60%;
  }
}

.slideout{
  animation: 0.5s slide-out ease-out;
}

@keyframes slide-out {
  0%{
    width: 60%;
  }

  100%{
    width: 0%;
  }
}

.inp-container{
  width: 47%;
  margin-right: 20px;
}

.inp-container small{
  font-weight: 600;
  font-size: 16px;
}

.pop-input input, select{
  height: 40px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;

  border: none;
  border-radius: 10px;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: var(--lighter);
  color: var(--dark);
}


.title-input{
  border: none;
  height: 60px;
  font-size: 30px;
  font-weight: 700;
  background-color: transparent;
  color: var(--dark);
  width: 70%;
}

.order-numbers .numbers{
  max-height: 230px;
  overflow-y: auto;
  margin-bottom: 20px;
  padding-right: 20px;
  background: var(--lighter);
  padding: 20px;

  border: solid 2px var(--lighter);

}

.order-numbers .numbers::-webkit-scrollbar{
  width: 10px;
  background-color: var(--lighter);
  border-radius: 50px;
}

.order-numbers .numbers::-webkit-scrollbar-thumb{
  background: var(--purple);
  border-radius: 50px;
}

.order-numbers .numbers button{
  border: none;
  border-radius: 10px;
  height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;
  color: var(--dark);
  margin-bottom: 15px;
  margin-right: 15px;
}

.order-numbers .numbers button:hover{
  background: var(--dark-pink);
  color: white;
}


.add-number input{
  height: 40px;
  margin-top: 10px;

  border: none;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: var(--lighter);
  color: var(--dark);
}

.add-number button{
  height: 40px;
  margin-top: 10px;
  margin-left: 10px;
  font-weight: 700;
  border: none;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: var(--dark-pink);
  color: white;
}


.bubble-btns{
  border: none;
  border-radius: 10px;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 700;
  background-color: transparent;
  color: var(--dark);
  transition: 0.2s;
}

.bubble-btns:hover{
  background-color: transparent;
  color: var(--dark-pink);
}




.login{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}


.login .inner{
  max-width: 400px;
  padding-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
}

.login .title-min{
  font-size: 35px;
  font-weight: 800;
  font-family: "Poppins";
  color: var(--purple);
}

.login .inner input:focus{
  border: solid 1.5px var(--purple);
}
.login .inner input{
  width: 100%;
  border: solid 1.5px var(--purple);
  border-radius: 10px;
  height: 50px;
  margin-bottom: 15px;
  background-color: white;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.034);
}

.small{
  color: var(--dark);
  font-size: 13px;
}





/* printable */

.c-header {
  background: #2104f4;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  margin-top: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-family: "Heebo";
}

.c-header .texter {
  margin-top: 10px;
  margin-left: 15px;
}

.c-header .texter h3 {
  font-size: 20px;
  margin-top: 3px;
}

.c-header .texter p {
  font-size: 15px;
  margin-top: -10px;
}

.c-header p {
  margin-top: 10px;
}

.c-header img {
  width: 50px;
  height: 60px;
}

.c-inner {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.c-main .label {
  font-size: 15px;
  color: var(--light);
}

.c-main .label-content {
  font-size: 18px;
  font-weight: 700;
}

.c-numbers {
  padding-top: 20px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  column-gap: 10px;
  row-gap: 15px;
}

.c-numbers p {
  font-weight: 600;
  color: var(--light);
}


.c-footer{
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 15px;
  color: var(--dark);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-family: "Heebo";
}

.pointer{
  cursor: pointer;
}